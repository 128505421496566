import React from "react";
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import PageLayoutComponent from "../layouts/PageLayoutComponent";

import ArticlesListPageSectionComponent from "../sections/pages/ArticlesListPageSectionComponent";

import BannerBlockComponent from "../blocks/BannerBlockComponent";
import SidebarBlockComponent from "../blocks/SidebarBlockComponent";

import normalizeUrlHelper from "../../helpers/normalizeUrlHelper";

const CategoryTemplateComponent = ({ pageContext, data: { allMarkdownRemark }}) => {
    const {t} = useTranslation();
    const { category } = pageContext;

    // TEMP
    const page = {
        slug: "/categories/" + normalizeUrlHelper(category),
        metaTitle: 'Les actualités "' + category + '"',
        metaDescription: 'Les actualités générales et techniques à propos de la catégorie "' + category + '" du monde web.',
        title: category,
        cover: "categories-bg.jpg",
        content: {theme: "light"}
    };

    return (
        <PageLayoutComponent page={page}>
            <div className="container">
                <div className="d-flex justify-content-center mb-5 mb-md-0">
                    <span className="d-inline-block px-4 text-center">
                        {t("app.global.discoverArticlesAboutCategory")} <strong>{category}</strong>.
                    </span>
                </div>

                <div className="row">
                    <div className="col-12 col-md-3 col-xl-2 pt-md-5">
                        <SidebarBlockComponent />
                    </div>
                    <div className="col-12 col-md-9 col-xl-10">
                        <ArticlesListPageSectionComponent articlesFiltered={allMarkdownRemark} theme={page.content.theme} />
                    </div>
                </div>
            </div>

            <BannerBlockComponent catchPhrase={t("app.catchPhrase.haveYouProject")} />
        </PageLayoutComponent>
    );
};

export default CategoryTemplateComponent;

export const query = graphql`
    query ($language: String!, $category: String) {
        allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/^.*\\/(articles)\\/.*$/"}, frontmatter: {category: {in: [$category]}}},
            sort: {fields: frontmatter___date, order: DESC}
        ) {
            nodes {
                frontmatter {
                    slug
                    title
                    metaDescription
                    cover
                    date(formatString: "DD/MM/YYYY")
                    category
                }
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
